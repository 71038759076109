import {IMAGES} from "../../constants/IMAGES";
import {InputComponent} from "../global/InputComponent";
import * as React from "react";
import {ButtonComponent} from "../global/ButtonComponent";
import {validateValues} from "../../utils/validationUtils";
import {sendMessage} from "../../utils/formUtil";
import {AsideContext} from "../../hooks/asideContext";
import {CONFIG} from "../../constants/config";

const WorkAside = () => {
    const [formData, handleFormData] = React.useState({
        name: '',
        lastname: '',
        phone: '',
        email: '',
        message: ''
    })
    const [messagesErrors, handleMessagesErrors] = React.useState<any>({})
    const [sendStatus, handleSendStatus] = React.useState('PENDING');
    const {handleAsideData} = React.useContext(AsideContext);
    const onSend = () => {
        const validations = validateValues(
            formData,
            [
                {
                    key: 'name',
                    type: 'NOT_EMPTY',
                    message: 'Es necesario que ingrese su nombre'
                },
                {
                    key: 'lastname',
                    type: 'NOT_EMPTY',
                    message: 'Es necesario que ingrese su apellido',
                },
                {
                    key: 'phone',
                    type: 'MIN_LENGTH',
                    message: 'Es necesario un teléfono válido',
                    condition: 7
                },
                {
                    key: 'email',
                    type: 'VALID_EMAIL',
                    message: 'Es necesario un email válido'
                },
                {
                    key: 'message',
                    type: 'MIN_LENGTH',
                    message: 'Es necesario un mensaje válido',
                    condition: 10
                }
            ]
        )
        if ( validations.haveErrors ) {
            handleMessagesErrors(validations.errors)
        } else {
            sendMessage('work', formData)
            handleSendStatus('SUCCESS')
        }
    }
    const changeData = (value: string | number, id: string) => {
        handleMessagesErrors({})
        handleFormData({
            ...formData,
            [id]: value
        })
    }
    return <div className="about width-100">
        <div className="p-t align-center">
            <h4 className="text-primary primary-font">
                ¿Necesitas ingresos extra? ¿Tienes un vehículo?
            </h4>
            <h3 className="regular line-height-small">
                ¡NO PIERDAS ESTA OPORTUNIDAD!
            </h3>
        </div>
        <p className="p-t align-center flex-row flex-no-wrap flex-middle p-l-lg p-r-lg m-auto flex-center">
            <span className="size-1 flex-column">"</span>
            <span className="flex-column">
                <span>Requerimos vehículos asegurados para completar nuestra flota de autos.</span>
                {/*<span>Por favor llena el formulario adjunto o comunícate con nosotros si estás interesado.</span>*/}
                <span>Por favor escríbenos a nuestra línea de Whatsapp si estás interesado.</span>
                <h3 className="flex-row flex-center">Contactar por Whatsapp <a className="p-l text-primary strong flex-row flex-middle" href={`https://api.whatsapp.com/send?phone=${CONFIG.phoneSecondary}&text=Hola%20...%20Estoy%20interesad@%20en%20trabajar%20con%20ustedes`} target="_blank"><img width={24} height={24} src={IMAGES.WHATSAPP} className="icon-24x p-r-s" alt="Whatsapp"/> {CONFIG.phoneSecondary}</a></h3>
            </span>
        </p>
        {sendStatus === 'SUCCESS' ?
            <div className="width-100">
                <h2 className="text-primary line-height-small p-t p-b align-center">
                    ¡Hemos enviado tu mensaje con éxito!
                </h2>
                <p className="align-center">Tu Mensage se ha enviado con éxito, en la brevedad uno de nuestros asesores te contactará</p>
                <div className="p-t align-center">
                    <ButtonComponent buttonText="REGRESAR" onClick={() => handleAsideData({
                        aside: '',
                        showAside: false,
                        bottomTitle: '',
                        title: '',
                        subtitle: ''
                    })}/>
                </div>
            </div>
            : null
            // <>
            //     <div className="blocks-content width-100 p-t">
            //         <div className="block-item">
            //             <div className="p-b p-l p-r">
            //                 <InputComponent
            //                     label="Nombre"
            //                     placeholder="Ejm: Juan"
            //                     valueId="name"
            //                     value={formData.name}
            //                     onChangeValue={changeData}
            //                     icon={<img src={IMAGES.NAME_SELECT} alt="nombre" width={18} height={18}/>}
            //                     error={messagesErrors.name}
            //                 />
            //             </div>
            //         </div>
            //         <div className="block-item">
            //             <div className="p-b p-l p-r">
            //                 <InputComponent
            //                     label="Apellido"
            //                     placeholder="Ejm: Barros"
            //                     valueId="lastname"
            //                     value={formData.lastname}
            //                     onChangeValue={changeData}
            //                     icon={<img src={IMAGES.NAME_SELECT} alt="Apellido" width={18} height={18}/>}
            //                     error={messagesErrors.lastname}
            //                 />
            //             </div>
            //         </div>
            //     </div>
            //     <div className="blocks-content width-100">
            //         <div className="block-item">
            //             <div className="p-b p-l p-r">
            //                 <InputComponent
            //                     label="Email"
            //                     placeholder="Ejm: juan@email.com"
            //                     valueId="email"
            //                     value={formData.email}
            //                     onChangeValue={changeData}
            //                     icon={<img src={IMAGES.MAIL_FORM} alt="email" width={18} height={18}/>}
            //                     error={messagesErrors.email}
            //                 />
            //             </div>
            //         </div>
            //         <div className="block-item">
            //             <div className="p-b p-l p-r">
            //                 <InputComponent
            //                     label="Teléfono"
            //                     placeholder="Ejm: 0911111222"
            //                     valueId="phone"
            //                     value={formData.phone}
            //                     onChangeValue={changeData}
            //                     icon={<img src={IMAGES.CALL} alt="Teléfono" width={18} height={18}/>}
            //                     error={messagesErrors.phone}
            //                 />
            //             </div>
            //         </div>
            //     </div>
            //     <div className="blocks-content width-100">
            //         <div className="block-item">
            //             <div className="p-b p-l p-r">
            //                 <InputComponent
            //                     textArea={true}
            //                     label="Mensaje"
            //                     placeholder="Ejm: detalle de su vehículo"
            //                     valueId="message"
            //                     value={formData.message}
            //                     onChangeValue={changeData}
            //                     error={messagesErrors.message}
            //                 />
            //             </div>
            //         </div>
            //     </div>
            //     <div className="width-100 align-right p-r">
            //         <ButtonComponent buttonText="Enviar solicitud" buttonType="primary" onClick={onSend} />
            //     </div>
            // </>
        }
    </div>
}

export default WorkAside