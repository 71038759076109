import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import React from "react";

interface IDatePicker {
    onChange: (value: string, id: string) => void
    dateValue?: string
    label: string
    icon: string
    id: string
    type: 'WHITE' | 'DARK'
    disable?: boolean
    minDate?: string
    error?: string
}
const DatePickerComponent = (props: IDatePicker) => {
    const {onChange, icon, dateValue, id, label, type, disable, minDate, error} = props
    const handle = (date: any) => {
        onChange(date.toLocaleDateString("en-US"), id)
    }
    return <div>
        <Flatpickr
            disabled={disable}
            options={{ disableMobile: true, minDate: minDate ? new Date(minDate) : new Date()}}
            value={dateValue ? new Date(dateValue) : ''}
            onChange={([date]) => handle(date as any)}
            render={
                ({defaultValue, value, ...props}, ref) => {
                    return <button disabled={disable}
                        ref={ref as any}
                        className={`p-l-s p-r-s date-picker-component radius-default no-border cursor-pointer ${type} ${disable && 'disable'}`}
                    >
                        <span className="flex-row flex-middle flex-no-wrap label">
                            <span className="flex-column label-container flex-left p-r-s no-wrap">
                                <strong className="text-uppercase label-text size-small">{label}</strong>
                                <span className={`${value ? 'label-value' : 'label-placeholder'} size-default`}>
                                    {dateValue ? dateValue : '--/--/--'}
                                </span>
                            </span>
                            <span className="flex-column icon-area flex-center flex-middle">
                                <img src={icon} alt={label} />
                            </span>
                        </span>
                    </button>
                }
            }
        />
        {error && <div className="error">{error}</div>}
    </div>
}

export default DatePickerComponent