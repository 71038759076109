import * as React from 'react'
import {IMAGES} from "../../constants/IMAGES";
import useResponsive from "../../hooks/useResponsive";
import DatePickerComponent from "../global/DatePickerComponent";
import IconButtonComponent from "../global/IconButtonComponent";
import {CATEGORIES} from "../../data/vehicles";
import useDateFromTo from "../../hooks/useDateFromTo";
import {ModalContext} from "../../hooks/modalContext";
import {MODALS} from "./ModalContainer";
import {FilterContext} from "../../hooks/filtersContext";
import PromosContainer from "./PromosContainer";
import {CONFIG, siteRCK} from "../../constants/config";
import ReCAPTCHA from "react-google-recaptcha";

const waAction = () => {
    console.log('rca_2')
    window.open(
        `https://api.whatsapp.com/send?phone=${CONFIG.phone}&text=Hola%20Autofast,%20necesito%20información`,
        '_blank'
    )
}

const FooterComponent = () => {
    const [activeFilter, handleActiveFilter] = React.useState(false);
    const [openPromos, handleOpenPromos] = React.useState(false);
    const {handleModalData} = React.useContext(ModalContext);
    const recaptchaFooterRef = React.createRef();
    const {isMobile} = useResponsive()
    const {handleFilterData, filterData} = React.useContext(FilterContext);
    const [dateFrom, dateTo, changeDateFrom, changeDateTo] = useDateFromTo()
    const whCTA = isMobile ? {w: 24,h: 24} : {w: 36, h: 36}
    const filtersRef: any = React.useRef(null)
    const handleClickOutside = (event: Event): void => {
        if (!filtersRef?.current?.contains(event.target)) {
            handleActiveFilter(false)
        }
    }
    const onChangeDate = (value: string, key: string) => {
        if (key === 'from') {
            changeDateFrom(value)
            changeDateTo('')
        } else {
            changeDateTo(value)
        }
    }
    React.useEffect(() => {
        document.addEventListener('click', handleClickOutside)
        return (): void => document.removeEventListener('click', handleClickOutside)
    })
    const setForDates = () => {
        handleModalData({
            showModal: true,
            extraData: {
                from: dateFrom,
                to: dateTo
            },
            modal: MODALS.DATE_MODAL,
            title: 'ALQUILAR UN VEHÍCULO DEL',
            subtitle: `${dateFrom} al ${dateTo}`
        })
    }
    const setForWhatsapp = () => {
        handleModalData({
            showModal: true,
            extraData: {},
            modal: MODALS.WHATSAPP_MODAL,
            title: 'ALQUILAR TU VEHÍCULO',
            subtitle: 'POR WHATSAPP'
        })
    }
    const filterByCategory = (category: {name: 'camionetas', fullName: string, icon: string, iconP: string, id: string}) => {
        handleFilterData(category)
        setTimeout(() => {
            document.getElementById(category.id)?.scrollIntoView({block: "start", behavior: "smooth"})
        }, 400)
    }
    return  <>
            <footer className="main-footer">
            <div className="footer-ctas flex-row flex-no-wrap">
                <div className="flex-column flex-1 flex-left flex-center filters-column">
                    <a ref={filtersRef} onClick={() => handleActiveFilter(!activeFilter)} className="flex-column cta-action flex-center flex-middle">
                        <span className="icon-cta">
                            <img width={whCTA.w} height={whCTA.h} src={filterData?.icon || IMAGES.FILTER} alt="contactar por whatsapp"/>
                        </span>
                        <strong className="text-primary">Filtrar</strong>
                        <span className="text-white">{filterData?.name || 'Vehículos'}</span>
                    </a>
                    <div className={`filter-selector ${activeFilter && 'active-filters'}`} >
                        <h4 className="text-primary size-default no-wrap p-l">Filtrar por tipo de vehículo</h4>
                        <div className="flex-row category-list p-l p-r-xs">
                            {CATEGORIES.map((category, key) => {
                                const isActive = category.name === filterData?.name
                                return <IconButtonComponent
                                    key={key}
                                    extraClass="m-r-s p-l-xs p-r-xs"
                                    icon={category.icon}
                                    label={category.name}
                                    onPress={() => filterByCategory(category as any) }
                                    inactive={!isActive}
                                />
                            })
                            }
                        </div>
                    </div>
                </div>
                {!isMobile &&
                    <div className="flex-column flex-center flex-left by-date p-l p-r border-l border-gray border-lighten-2">
                        <h4 className="text-primary size-default no-wrap">Reservar para los días</h4>
                        <div className="flex-row">
                            <div className="flex-column p-r-s">
                                <DatePickerComponent
                                    type="DARK"
                                    label="Desde"
                                    icon={IMAGES.DATE_FROM_WHITE}
                                    id="from"
                                    dateValue={dateFrom}
                                    onChange={onChangeDate}
                                />
                            </div>
                            <div className="flex-column">
                                <DatePickerComponent
                                    type="DARK"
                                    label="Hasta"
                                    icon={IMAGES.DATE_TO_WHITE}
                                    id="to"
                                    dateValue={dateTo}
                                    onChange={onChangeDate}
                                    disable={!dateFrom}
                                    minDate={dateFrom}
                                />
                            </div>
                            <div className="flex-column p-l-s">
                                <IconButtonComponent
                                    icon={IMAGES.ARROW_RIGHT}
                                    label="Reservar"
                                    onPress={setForDates}
                                    disable={!dateTo || !dateFrom}
                                />
                            </div>
                        </div>
                    </div>
                }
                <div className="flex-column phone-column">
                    <a href={`tel:${CONFIG.phoneSecondary}`} className="flex-column cta-action flex-center flex-middle border-l border-gray border-lighten-1">
                        <span className="icon-cta">
                            <img width={whCTA.w} height={whCTA.h} src={IMAGES.PHONE} alt="contactar por whatsapp"/>
                        </span>
                        <strong className="text-primary">Reserva</strong>
                        <span className="text-white">Por llamada</span>
                    </a>
                </div>
                <div className="flex-column">
                    <form className="flex-column" onSubmit={(e) => {
                        e.preventDefault();
                        // @ts-ignore
                        recaptchaFooterRef?.current?.execute();
                    }}>
                        <ReCAPTCHA
                            ref={recaptchaFooterRef as any}
                            sitekey={siteRCK}
                            size="invisible"
                            onChange={waAction} badge="bottomleft"
                        />
                        <button className="flex-column cta-action flex-center flex-middle bg-gray bg-lighten-1 no-border">
                            <span className="icon-cta">
                                <img width={whCTA.w} height={whCTA.h} src={IMAGES.WHATSAPP_SVG} alt="contactar por whatsapp"/>
                            </span>
                            <strong className="text-primary">Alquilar por</strong>
                            <span className="text-white">Whatsapp</span>
                        </button>
                    </form>
                </div>
                <div className="flex-column">
                    <a onClick={() => handleOpenPromos(true)} className={`${openPromos && 'promo-active'} flex-column cta-action flex-center flex-middle`}>
                        <span className="icon-cta">
                            <img width={whCTA.w} height={whCTA.h} src={IMAGES.PROMO} alt="contratar promosion"/>
                        </span>
                        <strong className="text-primary">Contratar</strong>
                        <span className="text-white">Promociones</span>
                    </a>
                </div>
            </div>
            <div className="footer-address bg-black text-white">
                <div><strong className="p-r-s">QUITO: </strong> Eloy Alfaro y De los Nogales, Conjunto Prados de San Mateo</div>
                <div className="strong">09 5920 7287</div>
                <div className="strong">autofastuio@gmail.com</div>
            </div>
        </footer>
        <PromosContainer isOpen={openPromos} onClose={() => handleOpenPromos(false)} />
    </>
}

export default FooterComponent