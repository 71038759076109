type IValidationTypes = 'NOT_EMPTY' | 'MIN_LENGTH' | 'VALID_EMAIL'

declare global {
    interface String {
        isEmpty(): boolean
    }
}

String.prototype.isEmpty = function () {
    return this.length === 0 || !this.trim()
}

const isValidValue = (
    rule: IValidationTypes,
    value: string,
    condition?: number
) => {
    switch (rule) {
        case 'NOT_EMPTY':
            return value && !value.isEmpty()
        case 'VALID_EMAIL':
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value || '')) {
                return true
            }
            return false
        case 'MIN_LENGTH':
            const defaultConditionValue = 3
            return value && value.length >= (condition || defaultConditionValue)
        default:
            return true
    }
}

export interface IValidationErrors {
    key: string
    type: IValidationTypes
    message: string
    condition?: number
}

interface IValidationResponse {
    haveErrors: boolean
    errors: { [name: string]: string }
}

export const validateValues = (
    // eslint-disable-next-line
    values: { [name: string]: any },
    validations: IValidationErrors[]
): IValidationResponse => {
    let haveErrors = false
    const errors: any = {}
    Object.keys(values).forEach((valueKey) => {
        validations.forEach((validation) => {
            if (valueKey === validation.key) {
                if (
                    !isValidValue(validation.type, values[valueKey], validation.condition)
                ) {
                    haveErrors = true
                    errors[validation.key] = validation.message
                }
            }
        })
    })
    return {
        haveErrors,
        errors,
    }
}