import axios from 'axios';

export const sendMessage = async (url: string, data: any) => {
    if (url && data) {
        const urlRequest = `https://alquilaautos.com/form/${url}.php`;
        axios({
            method: 'post',
            url: urlRequest,
            data
        }).then((response) => {
            console.log(response)
        }).catch(e => {
            console.log('error', e)
        });
    }
}