import * as React from "react";
import {ModalContext} from "../../hooks/modalContext";
import {IMAGES} from "../../constants/IMAGES";
import {InputComponent} from "../global/InputComponent";
import {ButtonComponent} from "../global/ButtonComponent";
import {validateValues} from "../../utils/validationUtils";
import {sendMessage} from "../../utils/formUtil";
import {CONFIG, siteRCK} from "../../constants/config";
import ReCAPTCHA from "react-google-recaptcha";

const onSend = (formData: any, handleSendStatus: any) => {
    console.log('cf-3')
    handleSendStatus('SENDING')
    sendMessage('contacts', formData)
    setTimeout(() => {
        handleSendStatus('SUCCESS')
    }, 3000)
}

const ContactModal = () => {
    const [formData, handleFormData] = React.useState({
        name: '',
        phone: '',
        email: '',
        message: ''
    })
    const recaptchaRefCF = React.createRef();
    const {handleModalData} = React.useContext(ModalContext);
    const [messagesErrors, handleMessagesErrors] = React.useState<any>({})
    const [sendStatus, handleSendStatus] = React.useState('PENDING');
    const changeData = (value: string | number, id: string) => {
        handleMessagesErrors({})
        handleFormData({
            ...formData,
            [id]: value
        })
    }
    return <div className="width-100 contact-modal form-modal">
        <div className="left-area">
            <div>
                <img src={IMAGES.EMAIL_LINE} alt="contacto" className="icon-64x"/>
            </div>
            <div className="description-form">
                {/*En <strong className="text-primary">AUTOFAST</strong> nos interesa conocer tu opinión, inquietudes o sugerencias; por favor completa el siguiente formulario y uno de nuestros asesores te contactará de inmediato*/}
                En <strong className="text-primary">AUTOFAST</strong> nos interesa conocer tu opinión, inquietudes o sugerencias; por favor escríbenos a nuestro correo <a href="mailto:dvascona@gmail.com">dvascona@gmail.com</a>,
                o escríbenos a nuestra línea whatsapp <a className="text-primary strong" href={`https://api.whatsapp.com/send?phone=${CONFIG.phoneSecondary}&text=Hola%20...%20Necesito%20contactarme%20con%20un%20asesor`} target="_blank">{CONFIG.phoneSecondary}</a>
            </div>
        </div>
        {/*<div className="contact-form">*/}
        {/*    {sendStatus === 'SUCCESS' ?*/}
        {/*        <div className="width-100">*/}
        {/*            <h2 className="text-primary line-height-small p-t p-b align-center">*/}
        {/*                ¡Hemos enviado tu mensaje con éxito!*/}
        {/*            </h2>*/}
        {/*            <p>Tu Mensage se ha enviado con éxito, en la brevedad uno de nuestros asesores te contactará</p>*/}
        {/*            <div className="p-t align-center">*/}
        {/*                <ButtonComponent buttonText="REGRESAR" onClick={() => handleModalData({modal: '', showModal: false, subtitle: '', extraData: '', title: ''})} />*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*        : <div className="form-area width-100 p-t">*/}
        {/*            <form onSubmit={(e) => {*/}
        {/*                e.preventDefault();*/}
        {/*                const validations = validateValues(*/}
        {/*                    formData,*/}
        {/*                    [*/}
        {/*                        {*/}
        {/*                            key: 'name',*/}
        {/*                            type: 'NOT_EMPTY',*/}
        {/*                            message: 'Es necesario que ingrese sus nombres'*/}
        {/*                        },*/}
        {/*                        {*/}
        {/*                            key: 'phone',*/}
        {/*                            type: 'MIN_LENGTH',*/}
        {/*                            message: 'Es necesario un teléfono válido',*/}
        {/*                            condition: 7*/}
        {/*                        },*/}
        {/*                        {*/}
        {/*                            key: 'email',*/}
        {/*                            type: 'VALID_EMAIL',*/}
        {/*                            message: 'Es necesario un email válido'*/}
        {/*                        },*/}
        {/*                        {*/}
        {/*                            key: 'message',*/}
        {/*                            type: 'MIN_LENGTH',*/}
        {/*                            message: 'Es necesario un mensaje válido',*/}
        {/*                            condition: 10*/}
        {/*                        }*/}
        {/*                    ]*/}
        {/*                )*/}
        {/*                if ( validations.haveErrors ) {*/}
        {/*                    handleMessagesErrors(validations.errors)*/}
        {/*                } else {*/}
        {/*                    // @ts-ignore*/}
        {/*                    recaptchaRefCF?.current?.execute();*/}
        {/*                }*/}
        {/*            }}>*/}
        {/*                <div className="width-100 p-b">*/}
        {/*                    <InputComponent*/}
        {/*                        label="Ingresa tus nombres"*/}
        {/*                        placeholder="Ejm: Adriana Suarez"*/}
        {/*                        valueId="name"*/}
        {/*                        value={formData.name}*/}
        {/*                        onChangeValue={changeData}*/}
        {/*                        icon={<img src={IMAGES.NAME_SELECT} alt="Nombres" width={24} height={24}/>}*/}
        {/*                        error={messagesErrors?.name}*/}
        {/*                    />*/}
        {/*                </div>*/}
        {/*                <div className="width-100 p-b flex-row">*/}
        {/*                    <div className="flex-column flex-1 p-r-xs">*/}
        {/*                        <InputComponent*/}
        {/*                            label="Email"*/}
        {/*                            placeholder="Ejm: asuarez@email.com"*/}
        {/*                            valueId="email"*/}
        {/*                            value={formData.email}*/}
        {/*                            onChangeValue={changeData}*/}
        {/*                            icon={<img src={IMAGES.MAIL_FORM} alt="Email" width={18} height={18}/>}*/}
        {/*                            error={messagesErrors?.email}*/}
        {/*                        />*/}
        {/*                    </div>*/}
        {/*                    <div className="flex-column flex-1 p-l-xs">*/}
        {/*                        <InputComponent*/}
        {/*                            label="Teléfono"*/}
        {/*                            placeholder="Ejm: 0999123123"*/}
        {/*                            valueId="phone"*/}
        {/*                            value={formData.phone}*/}
        {/*                            onChangeValue={changeData}*/}
        {/*                            icon={<img src={IMAGES.CALL} alt="Teléfono" width={18} height={18}/>}*/}
        {/*                            error={messagesErrors?.phone}*/}
        {/*                        />*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*                <div className="width-100 p-b">*/}
        {/*                    <InputComponent*/}
        {/*                        textArea={true}*/}
        {/*                        label="Ingresa tu mensaje"*/}
        {/*                        placeholder="Ejm: Hola, los saluda..."*/}
        {/*                        valueId="message"*/}
        {/*                        value={formData.message}*/}
        {/*                        onChangeValue={changeData}*/}
        {/*                        error={messagesErrors?.message}*/}
        {/*                    />*/}
        {/*                </div>*/}
        {/*                <div className="width-100 align-right">*/}
        {/*                    <ButtonComponent formButton={true} buttonText="Contactar" buttonType="primary" disabled={sendStatus === 'SENDING'}/>*/}
        {/*                    <ReCAPTCHA*/}
        {/*                        ref={recaptchaRefCF as any}*/}
        {/*                        sitekey={siteRCK}*/}
        {/*                        size="invisible"*/}
        {/*                        onChange={() => onSend(formData, handleSendStatus)}*/}
        {/*                        badge="bottomleft"*/}
        {/*                    />*/}
        {/*                </div>*/}
        {/*            </form>*/}
        {/*        </div>*/}
        {/*    }*/}
        {/*</div>*/}
    </div>
}

export default ContactModal