import ReCAPTCHA from "react-google-recaptcha";
import {CONFIG, siteRCK} from "../../constants/config";
import React from "react";

interface ICTAButton {
    onClick?: () => void
    text: string
    href?: string
    subText: string,
    icon: string
}

const goToWA = () => {
    console.log('rca_1')
    window.open(
        `https://api.whatsapp.com/send?phone=${CONFIG.phoneSecondary}&text=Hola%20Autofast,%20necesito%20información`,
        '_blank'
    )
}

const CTAButtonComponent = (props: ICTAButton) => {
    const recaptchaRef = React.createRef();
    const {onClick, icon, text, subText, href} = props
    const content = <span className="flex-row flex-middle flex-no-wrap">
            <span className="flex-column icon-area bg-white flex-center flex-middle">
                <img src={icon} alt={`${text} ${subText}`} />
            </span>
            <span className="flex-column text-white flex-left size-small p-l-s no-wrap">
                <strong className="text-uppercase">{text}</strong>
                <span>{subText}</span>
            </span>
        </span>
    const classMain = "p-l p-r bg-primary cta-button-component radius-default no-border cursor-pointer flex-middle"
    if (href) {
        return <form className="flex-column" onSubmit={(e) => {
            e.preventDefault();
            // @ts-ignore
            recaptchaRef?.current?.execute();
        }}>
            <ReCAPTCHA
                ref={recaptchaRef as any}
                sitekey={siteRCK}
                size="invisible"
                onChange={goToWA} badge="bottomleft"
            />
            <button className={`${classMain} flex-row`} type="submit">
                {content}
            </button>
        </form>
    }
    return <button onClick={onClick} className={classMain}>
        {content}
    </button>
}

export default CTAButtonComponent